import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from '../styles/main.module.scss';
import galtPdf from '../assets/pdfs/galt.pdf';

const ProjectsPage = () => {
  return (
    <Layout>
      <SEO title="Projects" />
      <div className={styles.projectsContainer}>
        <div className={styles.projectsContent}>
          <StaticImage src={'../assets/images/galt_page1.png'} alt="1700 Galt" />
          <a href={galtPdf}>1700 Galt</a>
        </div>
      </div>
    </Layout>
  );
};

export default ProjectsPage;
